import React from 'react';
import Helmet from 'react-helmet';

import PropTypes from 'prop-types';

import config from 'utils/siteConfig';

const StructuredPerson = ({
  person
}) => {
  const {
    siteUrl,
    siteName,
    logo
  } = config

  const schema = {
    "@context": "https://schema.org",
    "@type": "Person",
    "jobTitle": person.title,
    "name": person.name,
    "url": `${siteUrl}`
  }
  return (
    <Helmet defer={false}>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

StructuredPerson.propTypes = {
  person: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })
};

export default StructuredPerson;
